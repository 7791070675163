/* eslint-disable */
export default {
  AUTH: {
    ACTIONS: {
      LOGIN: "Login",
      LOGIN_WITH_FACEBOOK: "Login with Facebook",
      LOGOUT: "Logout",
      RESET_PASSWORD: "Reset Password",
      SIGN_WITH_GOOGLE: "Sign in with Google"
    },
    LABELS: {
      EMAIL: "Email Address",
      USERNAME: "Username",
      PASSWORD: "Password"
    },
    QUESTIONS: {
      FORGOT_PASSWORD: "Forgot password?",
      HAVE_AN_ACCOUNT: "Have an account?"
    },
    TOASTS: {
      UPDATE_PROFILE: "Profile updated."
    }
  },
  BASE: {
    ACTIONS: {
      UPLOAD_IMAGE: "Upload Image",
      DECLINE: "Decline",
      LEARN_MORE: "Learn More",
      TOGGLE_MENU: "Toggle Menu",
      VIEW: "View",
      VIEW_MORE: "View More",
      VISIT: "Visit"
    },
    LABELS: {
      EVENT: "Event",
      OCCURED: "Occured",
      STATUS: "Status",
      SUBMITTED: "Submitted"
    },
    OPTIONS: {
      NO: "No",
      YES: "Yes"
    },
    STATUSES: {
      APPROVED: "Approved",
      PENDING: "Pending",
      REJECTED: "Rejected"
    },
    TEXT: {
      HERE: "here",
      NOT_AVAILABLE: "N/A"
    },
    TOASTS: {
      INTERNAL_SERVER_ERROR: "Something went wrong.",
      UNAUTHORIZED: "You have been logged out."
    }
  },
  BENEFITS: {
    ACTIONS: {
      ADD_BENEFICIARY: "Add Beneficiary",
      ADD_DEPENDENT: "Add Dependent",
      ADD_QUALIFYING_EVENT: "Add Qualifying Event",
      CANCEL_COVERAGE: "Cancel Coverage",
      CREATE_BENEFICIARY: "Create Beneficiary",
      CREATE_DEPENDENT: "Create Dependent",
      CREATE_QUALIFYING_EVENT: "Create Qualifying Event",
      ENROLL: "Enroll",
      SELECT_REASON: "Select reason...",
      SELECT_RELATIONSHIP: "Select relationship...",
      SWITCH_PLAN: "Switch Plan"
    },
    CHECKBOXES: {
      IMMEDIATE_COVERAGE: "Optional: I would like to request immediate effective coverage for my health benefits by paying the first month's premium upfront.",
      TERMS: "I agree to the terms for enrollment in and continuation of benefits coverage throughout the plan year."
    },
    HELPERS: {
      ADD_DEPENDENTS_1: "Do you have any dependents? Click ",
      ADD_DEPENDENTS_2: " to add one.",
      ADD_QUALIFYING_EVENT: "A qualifying event is a life-changing event—such as getting married, having a baby, or losing your job—that makes you eligible to enroll in benefits outside of an open enrollment period.",
      BENEFICIARY_AGE: "Please note: Children under age 18 can be named as a primary or contingent beneficiary. However, benefits cannot be given while they are still minors, the proceeds may be sent in their name to the legal guardian of the minor child's estate.",
      CANCEL_COVERAGE: "If you cancel enrollment in your plan, then you will be unable to enroll in coverage until you experience a qualifying event or open enrollment.",
      CHOOSE_PLAN: "Choose a plan from the options below.",
      DELETE_DEPENDENT: "If you delete this dependent, they will be removed from any plans they are currently enrolled in.",
      ENROLL_DEPENDENT: "In order to enroll this dependent, you will need to submit a qualifying event. Would you like to continue?",
      REMOVE_DEPENDENT: "If you remove this dependent from your plan, then you will be unable to enroll them in benefit coverage until you experience a qualifying event or open enrollment.",
      SELECT_GENDER: "To continue with benefits and erollment you must select a gender."
    },
    LABELS: {
      ADULT_CARE: "Adult Care",
      APPROVAL_PENDING: "Approval Pending",
      BENEFITS_SUMMARY: "Benefits Summary",
      CHILD_CARE: "Child Care",
      COST: "Cost",
      COVERAGE: "Coverage",
      DATE_OF_EVENT: "Date of Event",
      DAY_CAMP: "Day Camp",
      DENTAL: "Dental",
      DEPENDENTS: "Dependents",
      EDUCATION: "Education",
      EFFECTIVE: "Effective",
      ELECTRONICS: "Electronics",
      ENROLLED: "Enrolled",
      ENTERTAINMENT: "Entertainment",
      EVENT_TYPE: "Event Type",
      EVERYDAY_EXPENSES: "Everyday Expenses",
      EYE_CARE: "Eye Care",
      FASHION_BEAUTY: "Fashion + Beauty",
      FIRST_DEDUCTION: "First Deduction",
      HEALTH_WELLNESS: "Health + Wellness",
      INSURANCE: "Insurance",
      MASS_TRANSIT: "Mass-Transit",
      MEDICAL_EXPENSES: "Medical Expenses",
      PARKING: "Parking",
      PLAN: "Plan",
      PROOF_OF_EVENT: "Proof of Event",
      PROVIDER: "Provider",
      RELATIONSHIP: "Relationship",
      SELF: "Self",
      STEP: "Step",
      TOTAL: "Total",
      TRAVEL: "Travel",
      WAIVED: "Waived",
      WEEKLY: "Weekly"
    },
    LINKS: {
      ABENITY: "http://spur.employeeperks.com",
      HEALTH_CARE: {
        LINK: "https://healthcare.gov",
        TEXT: "HealthCare.gov",
      },
      NAIC: {
        LINK: "https://naic.org",
        TEXT: "naic.org",
      }
    },
    NAV: {
      BENEFICIARIES: "Beneficiaries",
      BENEFITS: "Benefits",
      DASHBOARD: "Dashboard",
      DENTAL: "Dental",
      DEPENDENTS: "Dependents",
      MEDICAL: "Medical",
      PERKS: "Perks Program",
      QUALIFYING_EVENTS: "Qualifying Events",
      SHORT_TERM_DISABILITY: "Short Term Disability",
      TERM_LIFE: "Term Life",
      VISION: "Vision"
    },
    QUESTIONS: {
      HOW_DO_I_ENROLL: "How do I enroll?",
      WHAT_DO_I_SAVE_MONEY_ON: "What do I save money on?",
      WHAT_IS_SPURS_PERKS_PROGRAM: "What is Spur's Perks Program?"
    },
    RELIANCE: {
      COMPANY_ADDRESS_1: "Home Office: 1900 East Golf Rd Suite 500",
      COMPANY_ADDRESS_2: "Schaumburg IL, 60173",
      COMPANY_NAME: "RELIANCE STANDARD LIFE INSURANCE COMPANY",
      DISCLAIMER: "This fixed indemnity policy may pay you a limited dollar amount if you’re sick or hospitalized. You’re still responsible for paying the cost of your care.",
      DISCLAIMER_HEADER: "IMPORTANT: This is a fixed indemnity policy, NOT health insurance",
      DISCLAIMER_ITEM_1: "The payment you get isn’t based on the size of your medical bill.",
      DISCLAIMER_ITEM_2: "There might be a limit on how much this policy will pay each year.",
      DISCLAIMER_ITEM_3: "This policy isn’t a substitute for comprehensive health insurance.",
      DISCLAIMER_ITEM_4: "Since this policy isn’t health insurance, it doesn’t have to include most Federal consumer protections that apply to health insurance.",
      INSURANCE_INFO_HEADER: "Looking for comprehensive health insurance?",
      INSURANCE_INFO_ITEM_1: "Visit {url} online or call {phone} (TTY: {tty}) to find health coverage options.",
      INSURANCE_INFO_ITEM_2: "To find out if you can get health insurance through your job, or a family member’s job, contact the employer.",
      PHONE: "18003182596",
      PHONE_TEXT: "1-800-318-2596",
      PHONE_TTY: "18558894325",
      PHONE_TTY_TEXT: "1-855-889-4325",
      QUESTIONS_HEADER: "Questions about this policy?",
      QUESTIONS_ITEM_1: "For questions or complaints about this policy, contact your State Department of Insurance. Find their number on the National Association of Insurance Commissioners’ website ({url}) under 'Insurance Departments.'",
      QUESTIONS_ITEM_2: "If you have this policy through your job, or a family member’s job, contact the employer.",
    },
    STEPS: {
      PERKS: {
        STEP_2: 'Select "Forgot Username or Password" in the yellow box at the top of the screen.',
        STEP_3: "Enter your email address that you use to access Spur.",
        STEP_4: 'Click "Send Instructions".',
        STEP_5: "Check your email for instructions on how to reset your password. Follow those instructions.",
        STEP_6: "Start saving on the spending that matters!"
      },
    },
    TEASERS: {
      DENTAL: "Enroll in a national dental plan that includes 80% coverage for preventive services, 60% coverage for basic services, and 50% coverage for major services.",
      DISABILITY_TERM_LIFE: "Ensure the financial well-being of yourself and your family by enrolling in a short term disability / term life plan.",
      MEDICAL: "Choose the medical plan that works for you, your family, and your wallet with costs starting as low as $17.56 per week for coverage that makes a difference.",
      PERKS: "Have more fun for less with access to over 300,000 discounts on everything from movie tickets to vacation packages.",
      VISION: "Enroll in a national vision plan that includes free yearly vision exams and $150 annual allowance for frames or $130 annual allowance for contacts."
    },
    TEXT: {
      ABENTITY_QUESTIONS: "Send any questions to the Abenity team at ",
      ADULT_CARE: "Includes transportation costs",
      CHILD_CARE: "Day care, nannies, some pre-school",
      CLOSED: "Enrollment is closed until",
      DAY_CAMP: "Summer camp, before school, after school",
      DENTAL: "Cleaning, x-rays, procedures",
      EDUCATION: "Child care services, foreign language programs, higher education programs, trade school programs",
      ELECTRONICS: "Cable television, cell phones, computers, software",
      ENTERTAINMENT: "Amusement park passes, dinner theaters, movie tickets, sporting event passes",
      EVERYDAY_EXPENSES: "Auto repair services, furniture, groceries, home improvement services",
      EYE_CARE: "Glasses, contacts",
      FASHION_BEAUTY: "Clothing, jewelry, shoes, spa treatments",
      HEALTH_WELLNESS: "Athletic clubs, eyewear, personal training services, pharmacy benefits",
      INSURANCE: "Auto insurance, identity theft services, life insurance programs, pet insurance",
      MASS_TRANSIT: "Subway, train, ferry, bus",
      MEDICAL_EXPENSES: "Doctor co-pays, coinsurance",
      OPEN: "Enrollment ends in",
      PARKING: "Street parking, parking lots, garages",
      PERKS: "You now have access to over 302,000 exclusive discounts on everything from amusement park passes and movie tickets to car rentals and cell phone plans through our partnership with Abenity. Access your account to start spending less money on more fun for you, your friends, and your family!",
      PLAN: "{category} Plan",
      QUALIFYING_EVENT: "You have submitted a qualifying event. It must be approved before your enrollment can be completed. We are reviewing your event and will let you know as soon as it is approved.",
      TRAVEL: "Car rentals, cruises, hotels, vacation packages"
    },
    TOASTS: {
      ADD_BENEFICIARY: "Beneficiary added.",
      ADD_COVERAGE: "Coverage added.",
      ADD_DEPENDENT: "Dependent added.",
      ADD_QUALIFYING_EVENT: "Qualifying event added.",
      CANCEL_COVERAGE: "Coverage cancelled.",
      DELETE_BENEFICIARY: "Beneficiary deleted.",
      DELETE_DEPENDENT: "Dependent deleted.",
      REMOVE_DEPENDENT: "Dependent removed."
    },
    TOOLTIPS: {
      CLOSED: "In order to enroll, you have to wait for the next open enrollment or submit a qualifying event.",
      OPEN: "After enrollment ends, you will have to wait for the next open enrollment or submit a qualifying event to enroll again."
    }
  },
  FOOTER: {
    LINKS: {
      PRIVACY: "https://spurjobs.com/privacy-policy/en/",
      TERMS: "https://spurjobs.com/terms-of-service/"
    },
    NAV: {
      PRIVACY: "Privacy Policy",
      TERMS: "Terms & Conditions"
    },
    TEXT: {
      COPYRIGHT: "All rights reserved."
    }
  },
  FORMS: {
    ACTIONS: {
      CANCEL: "Cancel",
      EDIT: "Edit",
      DELETE: "Delete",
      NEXT: "Next",
      RESET: "Reset",
      REMOVE: "Remove",
      SELECT: "Select",
      SUBMIT: "Submit",
      TOGGLE: "Toggle"
    },
    LABELS: {
      OPTIONAL: "optional"
    },
    QUESTIONS: {
      ARE_YOU_SURE: "Are you sure?"
    }
  },
  TIME: {
    LABELS: {
      DAY: "day",
      DAYS: "days"
    },
  },
  USER: {
    ACTIONS: {
      EDIT_PROFILE: "Edit Profile",
      SELECT_GENDER: "Select gender...",
      SELECT_STATE: "Select state...",
      UPDATE_PROFILE: "Update Profile"
    },
    HEADINGS: {
      ACCOUNT_INFORMATION: "Account Information",
      PROFILE: "Profile"
    },
    LABELS: {
      ADDRESS: "Address",
      AGE: "Age",
      BIRTH_DATE: "Birthday",
      CITY: "City",
      FIRST_NAME: "First Name",
      GENDER: "Gender",
      LAST_NAME: "Last Name",
      MIDDLE_INITIAL: "Middle Initial",
      MIDDLE_NAME: "Middle Name",
      NAME: "Name",
      POSTAL_CODE: "ZIP Code",
      PHONE: "Phone",
      REGION: "State",
      SSN: "Social Security Number"
    }
  },
  WORK: {
    ACTIONS: {
      SEARCH: 'Search',
      ASSIGN: 'Work It',
    },
    LABELS: {
      ARRIVAL: 'Arrival Instructions',
      DATE: 'Date',
      DETAILS: 'Details',
      LOCATION: 'Location',
      NO_WORK: 'No additional shifts available at this time',
      PARKING: 'Parking',
      PAY: 'Pay',
      SEARCH: 'Search',
      SCHEDULE: 'Schedule',
      SHIFTS: 'Shifts',
      UNIFORM: 'Uniform',
    },
    NAV: {
      BETA: 'BETA',
      WORK: 'Work',
      AVAILABLE_WORK: 'Available Shifts',
    },
    TOASTS: {
      SHIFT_ASSIGNED: 'Shift filled. It will now appear in your schedule.',
      SHIFTS_ASSIGNED: 'Shifts filled. They will now appear in your schedule.',
      SHIFT_ASSIGNED_ERROR: 'There was an error while assigning the shift.',
      SHIFTS_ASSIGNED_ERROR: 'There was an error while assigning the shifts.',
    }
  },
};
